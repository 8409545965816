import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { Box, Grid, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { ResultImageInputWithPreviewComponent } from '../../../../components/form/result-image-input-with-preview/result-image-input-with-preview.component';
import { ResultTextField } from '../../../../components/form/result-text-field/result-text-field.component';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { InfoboxComponent } from '../../../../components/infobox/infobox.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';
import { irisCheckResultColors, irisCustomColors } from '../../../../theme';
import { CheckAttributeImages } from '../check-attribute-selection/check-attribute-select.component';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

const PREFIX = 'TrafficLightFormComponent';
const classes = {
  resultIconContainer: `${PREFIX}-resultIconContainer`,
  resultIconBackgroundContainer: `${PREFIX}-resultIconBackgroundContainer`,
  resultIcon: `${PREFIX}-resultIcon`,
  greenTrafficLightIcon: `${PREFIX}-greenTrafficLightIcon`,
  yellowTrafficLightIcon: `${PREFIX}-yellowTrafficLightIcon`,
  redTrafficLightIcon: `${PREFIX}-redTrafficLightIcon`,
  backgroundIcon: `${PREFIX}-backgroundIcon`,
  trafficLightImageContainer: `${PREFIX}-trafficLightImageContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.resultIconContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(-3),
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.resultIconBackgroundContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(-6),
    marginRight: theme.spacing(-3.25),
  },

  [`& .${classes.resultIcon}`]: {
    backgroundColor: irisCustomColors.irisWhite,
    zIndex: 4,
  },

  [`& .${classes.greenTrafficLightIcon}`]: {
    color: irisCheckResultColors.passed,
  },

  [`& .${classes.yellowTrafficLightIcon}`]: {
    color: irisCheckResultColors.sufficient,
  },

  [`& .${classes.redTrafficLightIcon}`]: {
    color: irisCheckResultColors.failed,
  },

  [`& .${classes.backgroundIcon}`]: {
    color: irisCustomColors.irisWhite,
    fontSize: 80,
    zIndex: 3,
  },

  [`& .${classes.trafficLightImageContainer}`]: {
    minHeight: 200,
  },
}));

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  trafficLightDescription: string;
  trafficLightGreenDescription: string;
  trafficLightYellowDescription: string;
  trafficLightRedDescription: string;
  trafficLightValues: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  trafficLightDescription: checkAttribute?.trafficLightDescription || '',
  trafficLightGreenDescription: checkAttribute?.trafficLightGreenDescription || '',
  trafficLightYellowDescription: checkAttribute?.trafficLightYellowDescription || '',
  trafficLightRedDescription: checkAttribute?.trafficLightRedDescription || '',
  trafficLightValues: '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const TrafficLightFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;

  const [trafficLightGreenImageUrl, setTrafficLightGreenImageUrl] = useState<string | undefined>(
    checkAttribute?.trafficLightGreenImageUrl
  );
  const [trafficLightYellowImageUrl, setTrafficLightYellowImageUrl] = useState<string | undefined>(
    checkAttribute?.trafficLightYellowImageUrl
  );
  const [trafficLightRedImageUrl, setTrafficLightRedImageUrl] = useState<string | undefined>(
    checkAttribute?.trafficLightRedImageUrl
  );
  const [chosenTrafficLightGreenImageFile, setChosenTrafficLightGreenImageFile] = useState<
    File | undefined
  >();
  const [chosenTrafficLightYellowImageFile, setChosenTrafficLightYellowImageFile] = useState<
    File | undefined
  >();
  const [chosenTrafficLightRedImageFile, setChosenTrafficLightRedImageFile] = useState<
    File | undefined
  >();

  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const { handleSubmit, errors, control, getValues, register } = methods;

  const removeTrafficLightGreenImage = () => {
    setTrafficLightGreenImageUrl(undefined);
    setChosenTrafficLightGreenImageFile(undefined);
  };

  const removeTrafficLightYellowImage = () => {
    setTrafficLightYellowImageUrl(undefined);
    setChosenTrafficLightYellowImageFile(undefined);
  };

  const removeTrafficLightRedImage = () => {
    setTrafficLightRedImageUrl(undefined);
    setChosenTrafficLightRedImageFile(undefined);
  };

  const validateTrafficLightValues = (): boolean => {
    const formData = getValues();

    const hasADescription =
      formData.trafficLightGreenDescription ||
      formData.trafficLightRedDescription ||
      formData.trafficLightYellowDescription;

    const isValid =
      hasADescription ||
      trafficLightRedImageUrl ||
      chosenTrafficLightRedImageFile ||
      trafficLightGreenImageUrl ||
      chosenTrafficLightGreenImageFile ||
      trafficLightYellowImageUrl ||
      chosenTrafficLightYellowImageFile;

    const returnValue = !!isValid || false;
    return returnValue;
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    if (formData.specification) {
      props.submit(
        {
          checkAttributeType: CheckAttributeType.TrafficLight,
          name: formData.checkAttributeName,
          specification: Number(formData.specification),
          sampleSize: Number(formData.sampleSize),
          trafficLightDescription: formData.trafficLightDescription,
          trafficLightGreenDescription: formData.trafficLightGreenDescription,
          trafficLightYellowDescription: formData.trafficLightYellowDescription,
          trafficLightRedDescription: formData.trafficLightRedDescription,
          trafficLightGreenImageUrl: trafficLightGreenImageUrl,
          trafficLightYellowImageUrl: trafficLightYellowImageUrl,
          trafficLightRedImageUrl: trafficLightRedImageUrl,
        },
        {
          trafficLightGreenImage: chosenTrafficLightGreenImageFile,
          trafficLightYellowImage: chosenTrafficLightYellowImageFile,
          trafficLightRedImage: chosenTrafficLightRedImageFile,
        }
      );
    }
  });

  return (
    <Root>
      <FormRoot>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formClasses.root}>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <DescriptionFrameComponent
                          checkAttributeType={CheckAttributeType.TrafficLight}
                        />
                        <GridItem s={12} xl={8}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} />}
                            defaultValue={''}
                            control={control}
                            name="trafficLightDescription"
                            label={t('data:checkAttribute.description')}
                            inputProps={{
                              'aria-label': t('data:checkAttribute.description'),
                              'data-testid': 'trafficLightDescription-input',
                            }}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: t('form:maxLength', { max: '256' }),
                              },
                            }}
                            error={errors.trafficLightDescription !== undefined}
                            helperText={
                              errors.trafficLightDescription &&
                              errors.trafficLightDescription.message
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>

            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.possibleResults')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer level={GridLevel.InputPaper}>
                    <input
                      type="hidden"
                      name="trafficLightValues"
                      defaultValue={' '}
                      ref={register({
                        validate: () =>
                          validateTrafficLightValues() ||
                          (t('data:checkAttribute.trafficLightValuesNotValid') as string),
                      })}
                    />
                    {errors.trafficLightValues && errors.trafficLightValues.message && (
                      <Grid item xs={12}>
                        <InfoboxComponent
                          headline={errors.trafficLightValues.message}
                          type="error"
                        ></InfoboxComponent>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6} lg={4}>
                      <Typography variant="h3">
                        {t('data:checkAttribute.trafficLightGreenDescription')}
                      </Typography>

                      <Box mt={-2} className={classes.trafficLightImageContainer}>
                        <Grid>
                          <Box className={classes.resultIconBackgroundContainer}>
                            <FiberManualRecordRoundedIcon className={classes.backgroundIcon} />
                          </Box>
                          <Box className={classes.resultIconContainer}>
                            <CheckIcon
                              className={`${classes.resultIcon} ${classes.greenTrafficLightIcon}`}
                            />
                          </Box>
                          <ResultImageInputWithPreviewComponent
                            changeImage={setChosenTrafficLightGreenImageFile}
                            chosenImageFile={chosenTrafficLightGreenImageFile}
                            removeImage={removeTrafficLightGreenImage}
                            imageUrl={trafficLightGreenImageUrl}
                            labelId={'greenImageInput'}
                            color={irisCheckResultColors.passed}
                          />
                        </Grid>
                      </Box>
                      <Box mt={-1}>
                        <Grid>
                          <Box className={classes.resultIconBackgroundContainer}>
                            <FiberManualRecordRoundedIcon className={classes.backgroundIcon} />
                          </Box>
                          <Box className={classes.resultIconContainer}>
                            <CheckIcon
                              className={`${classes.resultIcon} ${classes.greenTrafficLightIcon}`}
                            />
                          </Box>
                          <Controller
                            as={<ResultTextField bordercolor={irisCheckResultColors.passed} />}
                            control={control}
                            name="trafficLightGreenDescription"
                            placeholder={t('form:addDescription')}
                            multiline
                            rows={5}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              'data-testid': 'trafficLightGreenDescription-input',
                            }}
                            rules={{
                              maxLength: {
                                value: 1000,
                                message: t('form:maxLength', { max: '1000' }),
                              },
                            }}
                            error={errors.trafficLightGreenDescription !== undefined}
                            helperText={
                              errors.trafficLightGreenDescription &&
                              errors.trafficLightGreenDescription.message
                            }
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Typography variant="h3">
                        {t('data:checkAttribute.trafficLightYellowDescription')}
                      </Typography>
                      <Box mt={-2} className={classes.trafficLightImageContainer}>
                        <Grid>
                          <Box className={classes.resultIconBackgroundContainer}>
                            <FiberManualRecordRoundedIcon className={classes.backgroundIcon} />
                          </Box>
                          <Box className={classes.resultIconContainer}>
                            <CheckIcon
                              className={`${classes.resultIcon} ${classes.yellowTrafficLightIcon}`}
                            />
                          </Box>
                          <ResultImageInputWithPreviewComponent
                            changeImage={setChosenTrafficLightYellowImageFile}
                            chosenImageFile={chosenTrafficLightYellowImageFile}
                            removeImage={removeTrafficLightYellowImage}
                            imageUrl={trafficLightYellowImageUrl}
                            labelId={'yellowImageInput'}
                            color={irisCheckResultColors.sufficient}
                          />
                        </Grid>
                      </Box>
                      <Box mt={-1}>
                        <Grid>
                          <Box className={classes.resultIconBackgroundContainer}>
                            <FiberManualRecordRoundedIcon className={classes.backgroundIcon} />
                          </Box>
                          <Box className={classes.resultIconContainer}>
                            <CheckIcon
                              className={`${classes.resultIcon} ${classes.yellowTrafficLightIcon}`}
                            />
                          </Box>
                          <Controller
                            as={<ResultTextField bordercolor={irisCheckResultColors.sufficient} />}
                            control={control}
                            name="trafficLightYellowDescription"
                            placeholder={t('form:addDescription')}
                            multiline
                            rows={5}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              'data-testid': 'trafficLightYellowDescription-input',
                            }}
                            rules={{
                              maxLength: {
                                value: 1000,
                                message: t('form:maxLength', { max: '1000' }),
                              },
                            }}
                            error={errors.trafficLightYellowDescription !== undefined}
                            helperText={
                              errors.trafficLightYellowDescription &&
                              errors.trafficLightYellowDescription.message
                            }
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Typography variant="h3">
                        {t('data:checkAttribute.trafficLightRedDescription')}
                      </Typography>
                      <Box mt={-2} className={classes.trafficLightImageContainer}>
                        <Grid>
                          <Box className={classes.resultIconBackgroundContainer}>
                            <FiberManualRecordRoundedIcon className={classes.backgroundIcon} />
                          </Box>
                          <Box className={classes.resultIconContainer}>
                            <CloseIcon
                              className={`${classes.resultIcon} ${classes.redTrafficLightIcon}`}
                            />
                          </Box>
                          <ResultImageInputWithPreviewComponent
                            changeImage={setChosenTrafficLightRedImageFile}
                            chosenImageFile={chosenTrafficLightRedImageFile}
                            removeImage={removeTrafficLightRedImage}
                            imageUrl={trafficLightRedImageUrl}
                            labelId={'redImageInput'}
                            color={irisCheckResultColors.failed}
                          />
                        </Grid>
                      </Box>
                      <Box mt={-1}>
                        <Grid>
                          <Box className={classes.resultIconBackgroundContainer}>
                            <FiberManualRecordRoundedIcon className={classes.backgroundIcon} />
                          </Box>
                          <Box className={classes.resultIconContainer}>
                            <CloseIcon
                              className={`${classes.resultIcon} ${classes.redTrafficLightIcon}`}
                            />
                          </Box>
                          <Controller
                            as={<ResultTextField bordercolor={irisCheckResultColors.failed} />}
                            control={control}
                            name="trafficLightRedDescription"
                            placeholder={t('form:addDescription')}
                            multiline
                            rows={5}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              'data-testid': 'trafficLightRedDescription-input',
                            }}
                            rules={{
                              maxLength: {
                                value: 1000,
                                message: t('form:maxLength', { max: '1000' }),
                              },
                            }}
                            error={errors.trafficLightRedDescription !== undefined}
                            helperText={
                              errors.trafficLightRedDescription &&
                              errors.trafficLightRedDescription.message
                            }
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <SampleSizeComponent />
            <Grid item xs={12} sm={12}>
              <ButtonsFormComponent cancel={props.cancel} />
            </Grid>
          </form>
        </FormProvider>
      </FormRoot>
    </Root>
  );
};
