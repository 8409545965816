import { Box } from '@mui/material';
import React from 'react';

import { ImageInputWithPreviewComponent } from '../image-input-with-preview/image-input-with-preview.component';

export interface OwnProps {
  changeImage: (file: File | undefined) => void;
  chosenImageFile: File | undefined;
  removeImage: () => void;
  imageUrl?: string;
  labelId?: string;
  color?: string;
}

export const ResultImageInputWithPreviewComponent = (props: OwnProps) => {
  const { changeImage, chosenImageFile, removeImage, imageUrl, labelId, color } = props;

  return (
    <Box border={5} borderColor={color}>
      <ImageInputWithPreviewComponent
        changeImage={changeImage}
        chosenImageFile={chosenImageFile}
        removeImage={removeImage}
        imageUrl={imageUrl}
        labelId={labelId}
        flexible={true}
      />
    </Box>
  );
};
